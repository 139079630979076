/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import PropTypes from "prop-types"
import { useHistory, useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Error } from "@styled-icons/material/Error"
import { CheckCircle } from "@styled-icons/material/CheckCircle"
import { useTranslation } from "react-i18next"

// Components
import TextCard, { Text } from "../../atoms/TextCard"
import BoxWithInput from "../../atoms/BoxWithInput"
import SubmitButton from "../../atoms/SubmitButton"
import ImageWithText from "../../molecules/ImageWithText"
import ProductImage from "../../molecules/ProductImage"
import CustomSelect from "../../atoms/CustomSelect"

// Utils
import { withFirebase } from "../../../utils/Firebase"

import referenceTypes from "../../../utils/Constants/Reference_Types"
import countries from "../../../utils/Constants/Countries"

const planTypes = [
  { value: "premium", label: "Premium" },
  { value: "basic", label: "Básico" },
]

const organizationRoles = [
  { value: "business_partner", label: "Administrador" },
  { value: "medical_visitor", label: "Visitador Médico" },
  { value: "client", label: "Empleado" },
]

const PersonalInfo = ({ firebase, user, isAgent, isDoctor }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { state } = useLocation()
  const { addToast } = useToasts()
  const [profilePicUrl, setProfilePicture] = useState({})
  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [userPlanType, setUserPlanType] = useState(
    planTypes.filter((p) => p.value === get(user, "plan", undefined))
  )
  const [role, setRole] = useState(
    organizationRoles.filter((p) => p.value === get(user, "role", undefined))
  )
  const [currentOrg, setCurrentOrg] = useState()

  const [referencerUser, setReferencerUser] = useState({})

  useEffect(() => {
    const findReferencer = (results) => {
      return results.find(
        (referencer) => referencer.referenceCode === user.reference
      )
    }

    const getUserByReference = async () => {
      const getUsersByCode = await firebase.getUserByReferenceCode(
        user.reference
      )
      const mapUsers = getUsersByCode.docs.map((d) => d.data())
      if (!isEmpty(mapUsers)) {
        const getReferencer = findReferencer(mapUsers)
        setReferencerUser(getReferencer)
      } else {
        const getOrgByCode = await firebase.getOrganizationByReferenceCode(
          user.reference
        )
        const mapOrganizations = getOrgByCode.docs.map((d) => d.data())
        const getReferencer = findReferencer(mapOrganizations)
        setReferencerUser(getReferencer)
      }
    }

    if (user.reference) {
      getUserByReference()
    }
  }, [firebase, user])

  useEffect(() => {
    if (get(user, "plan")) {
      setUserPlanType(
        planTypes.filter((p) => p.value === get(user, "plan", undefined))
      )
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const getOrg = async () => {
        const orgId = get(user, "orgId")
        const org = await firebase.org(orgId).get()

        setCurrentOrg({ ...org.data() })
      }
      getOrg()
    }
  }, [user])

  const onSaveData = async () => {
    try {
      let imageURL = get(user, "photoURL") || null

      setIsLoading(true)
      if (profilePicUrl.data) {
        const uploadDir = `admins/avatars/${user.email}/${profilePicUrl.data.name}`
        const snapshot = await firebase.uploadToStorage(
          uploadDir,
          profilePicUrl.data.blob
        )
        imageURL = await snapshot.ref.getDownloadURL()
      }

      if (isDoctor) {
        firebase
          .updateOrgDoctor({
            orgId: state.orgId,
            id: user.id,
            doctorData: formValues,
          })
          .then(() => {
            setIsLoading(false)
            addToast(t("saved_successfully"), {
              appearance: "success",
            })
          })
          .catch(() => {
            setIsLoading(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          })
      } else if (isAgent) {
        firebase
          .updateOrgAgent({
            orgId: state.orgId,
            id: user.id,
            agentData: formValues,
          })
          .then(() => {
            setIsLoading(false)
            addToast(t("saved_successfully"), {
              appearance: "success",
            })
          })
          .catch(() => {
            setIsLoading(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          })
      } else {
        firebase
          .saveUserData({
            email: user.email,
            data: { ...formValues, photoURL: imageURL },
          })
          .then(() => {
            setIsLoading(false)
            addToast(t("saved_successfully"), {
              appearance: "success",
            })
          })
          .catch(() => {
            setIsLoading(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          })
      }
    } catch {
      setIsLoading(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const onLoadedImage = (fieldName, data) => {
    setProfilePicture({ fieldName, data })
  }

  const lang = get(i18n, "language", "es").split("-")
  return (
    <Container>
      {!isAgent && !isDoctor && (
        <ImagesContainer>
          {user.userType === "admin" ? (
            <PhotoContainer>
              <ProductImage
                width={100}
                height={100}
                text={t("photo_label")}
                src={get(user, "photoURL")}
                onImageLoaded={onLoadedImage}
                fieldName="profileUrl"
                emptyMessage={t("profile_picture")}
              />
              <span>{t("photo_label")}</span>
            </PhotoContainer>
          ) : (
            <ImageWithText
              text={t("photo_label")}
              src={get(user, "photoURL")}
            />
          )}
          {user.userType === "provider" && (
            <>
              <ImageWithText
                text={t("signature_label")}
                src={get(user, ["personalSign"])}
              />
              <ImageWithText
                text={t("medic_stamp_label")}
                src={get(user, ["business", "companyStamp"])}
              />
              <ImageWithText
                text={t("business_logo_label")}
                src={get(user, ["business", "companyLogo"])}
              />
            </>
          )}
        </ImagesContainer>
      )}
      <InfoWrapper>
        <BoxWithInput
          labelName={t("name_label")}
          defaultValue={
            user.userType === "provider"
              ? user.displayName
                ? `${get(user, ["academic", "title"], "")} ${get(
                    user,
                    "displayName"
                  )}`
                : `${get(user, ["academic", "title"], "")} ${get(
                    user,
                    "name"
                  )} ${get(user, "surname1")}`
              : get(user, "displayName")
          }
          onChange={(event) =>
            setFormValues({ ...formValues, displayName: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, displayName: event.target.value })
          }
          type="text"
          id="displayName"
          name="displayName"
        />
        {isDoctor && (
          <BoxWithInput
            labelName={t("doctor_surname1_label")}
            defaultValue={get(user, "surname1")}
            onChange={(event) =>
              setFormValues({ ...formValues, surname1: event.target.value })
            }
            onBlur={(event) =>
              setFormValues({ ...formValues, surname1: event.target.value })
            }
            type="text"
            id="surname1"
            name="surname1"
          />
        )}
        {isDoctor && (
          <BoxWithInput
            labelName={t("doctor_surname2_label")}
            defaultValue={get(user, "surname2")}
            onChange={(event) =>
              setFormValues({ ...formValues, surname2: event.target.value })
            }
            onBlur={(event) =>
              setFormValues({ ...formValues, surname2: event.target.value })
            }
            type="text"
            id="surname2"
            name="surname2"
          />
        )}
        {(user.userType === "admin" || user.userType === "client") && (
          <BoxWithInput
            labelName={t("phone_label")}
            defaultValue={get(user, ["phoneNumber"])}
            onChange={(event) =>
              setFormValues({ ...formValues, phoneNumber: event.target.value })
            }
            onBlur={(event) =>
              setFormValues({ ...formValues, phoneNumber: event.target.value })
            }
            type="text"
            id="phoneNumber"
            name="phoneNumber"
          />
        )}
        {(isAgent || isDoctor) && (
          <>
            <TextCard label={t("email_address")} text={get(user, ["email"])} />
            <BoxWithInput
              labelName={t("phone_label")}
              defaultValue={get(user, ["phoneNumber"])}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  phoneNumber: event.target.value,
                })
              }
              onBlur={(event) =>
                setFormValues({
                  ...formValues,
                  phoneNumber: event.target.value,
                })
              }
              type="text"
              id="phoneNumber"
              name="phoneNumber"
            />
            {!isDoctor && (
              <PlanContainer>
                <CustomSelect
                  value={role}
                  formKey="role"
                  lngKey="orgs_rol"
                  label="orgs_rol"
                  handleChange={(event, value) => {
                    setFormValues({
                      ...formValues,
                      role: value,
                    })
                    setRole(organizationRoles.filter((r) => r.value === value))
                  }}
                  items={organizationRoles}
                />
              </PlanContainer>
            )}
            {isDoctor && (
              <>
                <PlanContainer>
                  <CustomSelect
                    value={userPlanType}
                    formKey="plan"
                    lngKey="plan_type"
                    label="plan_type"
                    handleChange={(event, value) => {
                      setFormValues({
                        ...formValues,
                        plan: value,
                      })
                      setUserPlanType(
                        planTypes.filter((p) => p.value === value)
                      )
                    }}
                    items={planTypes}
                  />
                </PlanContainer>
              </>
            )}
          </>
        )}
        {((isAgent && currentOrg?.type !== "business") || isDoctor) && (
          <TextCard
            label={t("reference_code")}
            text={get(user, ["referenceCode"])}
          />
        )}
        {!isAgent && !isDoctor && (
          <TextCard label={t("country_label")} text={get(user, ["country"])} />
        )}
        {user.userType === "provider" && (
          <>
            <TextCard
              label={t("personal_id")}
              text={get(user, ["idCard"], "")}
            />
            <TextCard
              label={t("contact_data_label")}
              text={`${get(user, ["phoneNumber"])} - ${get(user, ["email"])}`}
            />
            <TextCard
              label={t("profession_label")}
              text={
                user?.professionLangLabel
                  ? get(user, ["academic", "professionLangLabel"])
                  : get(user, ["academic", "profession"])
              }
            />
            <TextCard
              label={t("professional_entity_label")}
              text={get(user, ["academic", "bureau"])}
            />
            <TextCard
              label={t("description_label")}
              text={get(user, ["academic", "biography"])}
            />
            <TextCard
              label={t("professional_license_label")}
              text={get(user, ["academic", "license"])}
            />
            <TextCard
              label={t("forms_label")}
              handleClick={() =>
                history.push(`/users/${user.uid}/forms`, {
                  user,
                })
              }
            >
              <Checks>
                <Text isSimple completed={get(user, "academicFormReady")}>
                  {get(user, "academicFormReady") ? (
                    <CheckCircle size={16} color="green" />
                  ) : (
                    <Error size={16} />
                  )}
                  {t("academic_form", {
                    status: get(user, "academicFormReady")
                      ? t("completed")
                      : t("incompleted"),
                  })}
                </Text>
                <Text isSimple completed={get(user, "businessFormReady")}>
                  {get(user, "businessFormReady") ? (
                    <CheckCircle size={16} color="green" />
                  ) : (
                    <Error size={16} />
                  )}
                  {t("business_form", {
                    status: get(user, "businessFormReady")
                      ? t("completed")
                      : t("incompleted"),
                  })}
                </Text>
              </Checks>
            </TextCard>
            <TextCard
              label={t("registration_date")}
              text={new Date(get(user, "creationTime")).toLocaleString()}
            />
            <Title>{t("additiona_information")}</Title>
            <TextCard
              label={t("reference_type")}
              text={t(
                get(
                  referenceTypes,
                  [lang, user.referenceType],
                  t("noReference")
                )
              )}
            />
            <TextCard
              label={t("referenced_by")}
              text={
                get(referencerUser, "displayName", false)
                  ? get(referencerUser, "displayName", t("noReference"))
                  : get(referencerUser, "businessName", t("noReference"))
              }
            />
            {user?.business?.address && (
              <>
                <Title>{t("address")}</Title>
                <TextCard
                  label={t("country_label")}
                  text={
                    countries?.find(
                      (country) =>
                        country.value ===
                        get(user, "business.address.country", false)
                    )?.label
                  }
                />
                <TextCard
                  label={t("country_state")}
                  text={get(user, "business.address.state", false)}
                />
                <TextCard
                  label={t("country_city")}
                  text={get(user, "business.address.city", false)}
                />
                {user?.business?.address?.district && (
                  <TextCard
                    label={t("country_distric")}
                    text={get(user, "business.address.district", false)}
                  />
                )}
                <TextCard
                  label={t("address_details")}
                  text={get(user, "business.address.addressDetails", false)}
                />
                <TextCard
                  label={t("country_cords")}
                  text={`${get(
                    user,
                    "business.address.coordinates.latitude",
                    false
                  )}, ${get(
                    user,
                    "business.address.coordinates.longitude",
                    false
                  )}`}
                />
              </>
            )}
            {get(user, "activeStatusDate") && (
              <TextCard
                label={t("approval_date")}
                text={new Date(
                  get(user, "activeStatusDate") * 1000
                ).toLocaleString()}
              />
            )}
          </>
        )}
        {(user.userType === "admin" || isAgent || isDoctor) && (
          <SubmitButton
            iconName="save"
            isLoading={isLoading}
            onClick={onSaveData}
            labelName={t("save_button")}
          />
        )}
      </InfoWrapper>
    </Container>
  )
}

const Title = styled.h4``

export const Checks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  margin: 0px 16px;
`

const Container = styled.div`
  position: relative;
  height: calc(100% - 100px);
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  margin-top: 20px;
  & > div {
    margin: 0 15px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span {
    margin-top: 5px;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    text-align: center;
  }
`

const PlanContainer = styled.div`
  padding: 24px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 400px;
  text-align: center;
`

PersonalInfo.defaultProps = {
  isAgent: false,
  isDoctor: false,
}

PersonalInfo.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    userType: PropTypes.string,
    email: PropTypes.string,
    uid: PropTypes.string,
    id: PropTypes.string,
    referenceType: PropTypes.string,
    reference: PropTypes.string,
    business: PropTypes.shape(),
    professionLangLabel: PropTypes.string,
  }).isRequired,
  isAgent: PropTypes.bool,
  isDoctor: PropTypes.bool,

  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(PersonalInfo)
