/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import CryptoJS from "crypto-js"
import moment from "moment"
import { withFirebase } from "../../../utils/Firebase"
import {
  generatePassword,
  getReferenceCode,
  paymentChargeUrl,
} from "../../../utils/App"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

const planTypes = [
  { value: "premium", label: "Premium" },
  { value: "basic", label: "Básico" },
]

const recordTypes = [
  { value: "new", label: "Médico nuevo" },
  { value: "exisisting", label: "Médico existente" },
]

const DoctorDrawer = ({
  firebase,
  open,
  closeDrawer,
  orgId,
  orgName,
  country,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)
  const [isNewDoctor, setIsNewDoctor] = useState(true)
  const [orgDoctorList, setOrgDoctorList] = useState([])
  const [doctorList, setDoctorList] = useState([])
  const [newDoctorList, setNewDoctorList] = useState([])
  const [selectedDoctor, setSelectedDoctor] = useState("")
  const [currentOrg, setCurrentOrg] = useState()
  const createProvider = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserProvider`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
  const [planType, setPlanType] = useState(undefined)
  const [recordType, setRecordType] = useState(undefined)
  const [doctorselected, setDoctorselected] = useState(undefined)

  useEffect(() => {
    if (!isNewDoctor) {
      const getForm = async () => {
        const orgDoctors = await firebase.getOrgDoctorList(orgId)
        setOrgDoctorList(orgDoctors)
        const doctors = await firebase.getDoctorList()
        setDoctorList(doctors)
      }
      getForm()
    }
  }, [isNewDoctor])

  useEffect(() => {
    if (orgId) {
      const getOrg = async () => {
        const org = await firebase.org(orgId).get()
        setCurrentOrg({ ...org.data() })
      }
      getOrg()
    }
  }, [orgId])

  useEffect(() => {
    if (doctorList && doctorList.length > 0) {
      const existingDoctorList = orgDoctorList.map((d) => {
        return d.email
      })

      const candidateDoctors = doctorList.filter(
        (d) => !existingDoctorList.includes(d.email)
      )

      if (candidateDoctors.length > 0) {
        const result = []
        candidateDoctors.forEach((doctor) => {
          result.push({
            value: doctor.id,
            label: doctor.displayName,
          })
        })

        setNewDoctorList(result)
      }
    }
  }, [orgDoctorList, doctorList])

  const getInviteHeader = (type) => {
    let emailType = ""
    switch (type) {
      default:
        emailType = "Médico"
    }

    return emailType
  }

  const getEmailTemplate = (type) => {
    let templateId = 0
    switch (type) {
      default:
        templateId = 6081845
    }

    return templateId
  }

  const generatePayload = async (plan) => {
    let basic = 0
    let premium = 0
    let premiumFee = 0
    let basicFee = 0
    let subTotal = 0

    const pricingSnapshot = await firebase.getPricing()

    if (pricingSnapshot.exists) {
      const getData = pricingSnapshot.data()

      premiumFee = getData?.businessCharges?.premium
      basicFee = getData?.businessCharges?.basic
    }

    if (plan) {
      switch (plan) {
        case "basic":
          basic += 1
          break
        case "premium":
          premium += 1
          break
        default:
          break
      }
    }

    subTotal = basic * basicFee + premium * premiumFee

    const orgTokens = await firebase.getOrgTokens({
      orgId,
    })

    const addressSnapshot = await firebase.getOrgAddress(orgId)
    let orgAddress = null

    if (!addressSnapshot.empty) {
      addressSnapshot.forEach((d) => {
        const addressData = d.data()
        orgAddress = {
          city: addressData.address_city,
          country: addressData.address_country,
          line1: addressData.address_line1,
          line2: addressData.address_line2,
          state: addressData.address_state,
          zip: addressData.address_zip,
        }
      })
    }

    const clientPayload = {
      email: currentOrg.email,
      customerId: currentOrg.stripeUserId,
    }

    const chargePayload = {
      amount: subTotal,
      currency: "usd",
      description: `Doctor subscription: [${currentOrg?.businessName}]`,
      paymentType: "card",
    }

    // Set metadata
    const data = new Map()
    data.set("basicFee", basicFee)
    data.set("premiumFee", premiumFee)
    data.set("basic", basic)
    data.set("premium", premium)

    return {
      chargePayload: {
        client: clientPayload,
        charge: chargePayload,
        token: orgTokens[0].token,
        metadata: Array.from(data),
        isDirectCharge: true,
        transactionType: "BusinessSubscription",
      },
      invoice: {
        customerId: orgId,
        customerName: currentOrg.businessName,
        customerType: currentOrg.type,
        createdAt: Date.now(),
        email: currentOrg.email,
        paidAmount: chargePayload.amount,
        subTotal: chargePayload.amount,
        taxes: 0,
        total: chargePayload.amount,
        balance: 0,
        type: "receipt",
        address: orgAddress,
      },
      invoiceItems: [
        {
          amount: basicFee,
          description: "Basic package",
          qty: 1,
          qtyDoctors: basic,
          totalAmount: basic * basicFee,
        },
        {
          amount: premiumFee,
          description: "Premium package",
          qty: 1,
          qtyDoctors: premium,
          totalAmount: premium * premiumFee,
        },
      ],
    }
  }

  const paymentSubscription = async (plan, doctorId) => {
    if (currentOrg && !currentOrg.toActivate) {
      const { chargePayload, invoice, invoiceItems } = await generatePayload(
        plan
      )
      const token = await firebase.getIdToken()
      const res = await fetch(paymentChargeUrl, {
        method: "POST",
        body: JSON.stringify(chargePayload),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })

      if (res.ok) {
        const parsedRes = await res.json()

        const s = await firebase.getInvoiceConsecutive({
          country: currentOrg.country,
        })
        const now = new Date()
        const consecutive = currentOrg.country
          .toUpperCase()
          .concat(
            now.getFullYear().toString().slice(-2),
            (now.getMonth() + 1).toString().padStart(2, "0"),
            s.toString().padStart(4, "0")
          )

        const transaction = await firebase.getTransaction(
          parsedRes.transactionId
        )

        const { payment_method_details: paymentMethod, currency } =
          transaction.data()

        const invoiceId = await firebase.saveInvoice({
          invoiceData: {
            ...invoice,
            invoiceNumber: consecutive,
            transactionId: parsedRes.transactionId,
            currency,
            cardBrand: paymentMethod?.card?.brand,
            last4: paymentMethod?.card?.last4,
          },
        })

        await firebase.saveInvoiceItems({
          invoiceId,
          invoiceItems,
        })

        await firebase.updateOrgDoctor({
          orgId,
          id: doctorId,
          doctorData: {
            subscriptionPaid: true,
            subscriptionPaymentRetry: 0,
            nextPaymentDate: moment(now)
              .add(1, "months")
              .startOf("day")
              .valueOf(),
          },
        })
      }
    }
  }

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_doctor")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            type: "",
            email: undefined,
            password: generatePassword(),
            displayName: undefined,
            surname1: "",
            surname2: "",
            phoneNumber: undefined,
            recordType: "new",
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              const token = await firebase.getIdToken()
              const getCodeToReference = getReferenceCode()

              if (!isNewDoctor && selectedDoctor !== "") {
                const newDoctor = await firebase.addOrgDoctor({
                  orgId,
                  email: values.email,
                  doctorData: {
                    email: values.email,
                    displayName: values.displayName,
                    phoneNumber: values.phoneNumber,
                    surname1: values.surname1,
                    surname2: values.surname2,
                    phone: values.phoneNumber,
                    active: true,
                    referenceCode: getCodeToReference,
                    plan: values.type,
                    role: "doctor",
                    createdAt: Date.now(),
                  },
                })

                await paymentSubscription(values.type, newDoctor.id)

                await firebase.addDoctorOrganization(values.email, orgId)

                // Update Provider AccessMode
                await firebase.updateProvider({
                  email: values.email,
                  providerData: {
                    accessMode: "mix",
                  },
                })

                addToast(t("saved_successfully"), {
                  appearance: "success",
                })
                resetForm({ value: "" })
                setIsCreating(false)
                closeDrawer()
              } else {
                // const createdUser =
                await fetch(createProvider, {
                  method: "POST",
                  body: JSON.stringify({
                    user: {
                      email: values.email.toLowerCase(),
                      password: CryptoJS.AES.encrypt(
                        values.password,
                        "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
                      ).toString(),
                      displayName: `${values.displayName} ${values.surname1} ${values.surname2}`,
                      name: values.displayName,
                      surname1: values.surname1,
                      surname2: values.surname2,
                      phoneNumber: values.phoneNumber,
                      country,
                      userType: "provider",
                      license: "",
                      plan: values.type,
                    },
                  }),
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }).then(async (data) => {
                  try {
                    if (data.error || data.status !== 200) {
                      const parsedRes = await data.json()

                      setIsCreating(false)
                      if (
                        parsedRes.error.message.includes(
                          "The email address is already in use"
                        )
                      ) {
                        addToast(
                          "La dirección de correo ya está siendo utilizada por otra cuenta",
                          {
                            appearance: "error",
                            placement: "top-left",
                          }
                        )
                      } else {
                        addToast(t("unexpected_error"), {
                          appearance: "error",
                          placement: "bottom-center",
                        })
                      }
                    } else {
                      const templateId = getEmailTemplate(values.type)
                      // This timeout gives time for the firebase trigger to be executed
                      setTimeout(async () => {
                        await fetch(mailerUrl, {
                          method: "POST",
                          body: JSON.stringify({
                            data: {
                              templateId,
                              email: values.email,
                              variables: {
                                subject: `Te han invitado como ${getInviteHeader(
                                  values.type
                                )} a la organización ${orgName}`,
                                orgName,
                                displayName: values.displayName,
                                password: values.password,
                                email: values.email,
                                url: `${process.env.REACT_APP_WEB_LOGIN_URL}`,
                              },
                            },
                          }),
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                        })

                        await firebase.updateProvider({
                          email: values.email,
                          providerData: {
                            accessMode: "business",
                          },
                        })

                        const newDoctor = await firebase.addOrgDoctor({
                          orgId,
                          email: values.email,
                          doctorData: {
                            email: values.email,
                            displayName: values.displayName,
                            phoneNumber: values.phoneNumber,
                            surname1: values.surname1,
                            surname2: values.surname2,
                            phone: values.phoneNumber,
                            active: true,
                            referenceCode: getCodeToReference,
                            plan: values.type,
                            role: "doctor",
                            createdAt: Date.now(),
                          },
                        })

                        await firebase.addDoctorOrganization(
                          values.email,
                          orgId
                        )

                        await paymentSubscription(values.type, newDoctor.id)

                        addToast(t("saved_successfully"), {
                          appearance: "success",
                          placement: "bottom-center",
                        })
                        resetForm({ value: "" })
                        setIsCreating(false)
                        closeDrawer()
                      }, 2500)
                    }
                  } catch (e) {
                    addToast(t("unexpected_error"), {
                      appearance: "error",
                      placement: "bottom-center",
                    })
                  }
                })
              }
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            type: Yup.string().required(t("error_all_fields_required")),
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            displayName: Yup.string().required(t("error_all_fields_required")),
            surname1: Yup.string().required(t("error_all_fields_required")),
            surname2: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>
                Ingrese los datos de contacto del nuevo médico que desea crear
                dentro de la organización.
              </p>
              <CustomSelect
                value={recordType}
                formKey="recordType"
                lngKey="create_doctor"
                handleChange={(event, value) => {
                  if (value === "new") setIsNewDoctor(true)
                  else setIsNewDoctor(false)
                  setRecordType(recordTypes.filter((r) => r.value === value))

                  props.setFieldValue("email", "")
                  props.setFieldValue("displayName", "")
                  props.setFieldValue("surname1", "")
                  props.setFieldValue("surname2", "")
                  props.setFieldValue("phoneNumber", "")
                  setPlanType(undefined)
                  setDoctorselected(undefined)
                }}
                items={recordTypes}
                showError={
                  !props.values.recordType && Boolean(props.errors.recordType)
                }
              />
              <CustomSelect
                value={planType}
                formKey="type"
                lngKey="plan_type"
                handleChange={(event, value) => {
                  props.setFieldValue("type", value)
                  setPlanType(planTypes.filter((p) => p.value === value))
                }}
                items={planTypes}
                showError={!props.values.type && Boolean(props.errors.type)}
              />
              {!isNewDoctor && (
                <CustomSelect
                  value={doctorselected}
                  formKey="doctorselected"
                  lngKey="select_doctor"
                  handleChange={(event, value) => {
                    setSelectedDoctor(value)
                    setDoctorselected(
                      newDoctorList.filter((d) => d.value === value)
                    )
                    const doctorToAdd = doctorList.filter(
                      (d) => d.email === value.toString()
                    )

                    props.setFieldValue("email", value)
                    props.setFieldValue(
                      "displayName",
                      doctorToAdd[0].displayName
                    )
                    props.setFieldValue("surname1", doctorToAdd[0].surname1)
                    props.setFieldValue("surname2", doctorToAdd[0].surname2)
                    props.setFieldValue(
                      "phoneNumber",
                      doctorToAdd[0].phoneNumber.number
                        ? doctorToAdd[0].phoneNumber.number
                        : doctorToAdd[0].phoneNumber
                    )
                  }}
                  items={newDoctorList}
                />
              )}
              {isNewDoctor && (
                <>
                  <CustomInput
                    touched={props.touched.displayName}
                    errors={props.errors.displayName}
                    icon="user"
                    value={props.values.displayName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    labelName={t("display_name_label")}
                    type="text"
                    id="displayName"
                    name="displayName"
                  />
                  <CustomInput
                    touched={props.touched.surname1}
                    errors={props.errors.surname1}
                    icon="user"
                    value={props.values.surname1}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    labelName={t("doctor_surname1_label")}
                    type="text"
                    id="surname1"
                    name="surname1"
                  />
                  <CustomInput
                    touched={props.touched.surname2}
                    errors={props.errors.surname2}
                    icon="user"
                    value={props.values.surname2}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    labelName={t("doctor_surname2_label")}
                    type="text"
                    id="surname2"
                    name="surname2"
                  />
                  <CustomInput
                    touched={props.touched.email}
                    errors={props.errors.email}
                    icon="email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    labelName={t("email_address")}
                    type="email"
                    id="email"
                    name="email"
                  />
                  <CustomInput
                    touched={props.touched.phoneNumber}
                    errors={props.errors.phoneNumber}
                    icon="phone"
                    value={props.values.phoneNumber}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    labelName={t("phone_label")}
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                  />
                </>
              )}

              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

DoctorDrawer.defaultProps = {
  open: false,
}

DoctorDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}
export default withFirebase(DoctorDrawer)
