import React, { useState } from "react"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"
import get from "lodash.get"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { withFirebase } from "../../../utils/Firebase"
import ProductImage from "../../molecules/ProductImage"
import ImageWithText from "../../molecules/ImageWithText"
import TextCard from "../../atoms/TextCard"
import SubmitButton from "../../atoms/SubmitButton"
import BoxWithInput from "../../atoms/BoxWithInput"
import CustomSelect from "../../atoms/CustomSelect"
import businessEmployees from "../../../utils/Constants/Business_Employees"

const OrgInfo = ({ firebase, user, organization }) => {
  const { t, i18n } = useTranslation()
  const { addToast } = useToasts()
  const [logoPicUrl, setLogoPicture] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({})
  const lang = get(i18n, "language", "es").split("-")
  const [employeeRange, setEmployeeRange] = useState(
    businessEmployees[lang].filter(
      (r) =>
        r.value ===
        get(organization, ["employeeRange"], businessEmployees[lang][0].value)
    )
  )

  const idField =
    organization.type === "drugstore" ? "licenceNumber" : "legalId"
  const logoField = organization.type === "drugstore" ? "bannerUrl" : "logoURL"

  const onSaveData = async () => {
    try {
      let imageURL = get(user, logoField) || null

      setIsLoading(true)
      if (logoPicUrl.data) {
        const uploadDir = `organizations/${organization.uid}/avatars/${logoPicUrl.data.name}`
        const snapshot = await firebase.uploadToStorage(
          uploadDir,
          logoPicUrl.data.blob
        )
        imageURL = await snapshot.ref.getDownloadURL()
      }

      firebase
        .updateOrgData({
          orgId: organization.uid,
          orgData: { ...formValues, [logoField]: imageURL },
        })
        .then(() => {
          setIsLoading(false)
          addToast(t("saved_successfully"), {
            appearance: "success",
          })
        })
        .catch(() => {
          setIsLoading(false)
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    } catch {
      setIsLoading(false)
    }
  }

  const onLoadedImage = (fieldName, data) => {
    setLogoPicture({ fieldName, data })
  }

  return (
    <Container>
      <ImagesContainer>
        <PhotoContainer>
          {get(user, "name") ? (
            <ProductImage
              width={100}
              height={100}
              text={t("logo_label")}
              src={get(user, logoField)}
              onImageLoaded={onLoadedImage}
              fieldName={logoField}
              emptyMessage={t("logo_label")}
            />
          ) : (
            <ImageWithText text={t("logo_label")} src={get(user, logoField)} />
          )}
          {get(user, "name") && <span>{t("logo_label")}</span>}
        </PhotoContainer>
      </ImagesContainer>
      <InfoWrapper>
        <BoxWithInput
          labelName={t("name_label")}
          defaultValue={get(user, ["name"])}
          onChange={(event) =>
            setFormValues({ ...formValues, name: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, name: event.target.value })
          }
          type="text"
          id="name"
          name="name"
        />
        <BoxWithInput
          labelName={t("business_name")}
          defaultValue={get(user, ["businessName"])}
          onChange={(event) =>
            setFormValues({ ...formValues, businessName: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, businessName: event.target.value })
          }
          type="text"
          id="businessName"
          name="businessName"
        />
        {get(organization, "createdAt") && (
          <TextCard
            label={t("orgs_creationDate")}
            text={moment(get(organization, ["createdAt"])).format("DD-MM-YYYY")}
          />
        )}
        <TextCard label={t("country_label")} text={get(user, ["country"])} />
        <TextCard label={t("email_address")} text={get(user, "email")} />
        <BoxWithInput
          labelName={t("id_label")}
          defaultValue={get(user, [idField])}
          onChange={(event) =>
            setFormValues({ ...formValues, [idField]: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, [idField]: event.target.value })
          }
          type="text"
          id={idField}
          name={idField}
        />
        <BoxWithInput
          labelName={t("phone_label")}
          defaultValue={get(user, ["phoneNumber"])}
          onChange={(event) =>
            setFormValues({ ...formValues, phoneNumber: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, phoneNumber: event.target.value })
          }
          type="text"
          id="phoneNumber"
          name="phoneNumber"
        />
        {get(user, ["type"]) === "business" && (
          <>
            <PlanContainer>
              <CustomSelect
                value={employeeRange}
                formKey="employeeRange"
                lngKey="employeeRange"
                label="employeeRange"
                handleChange={(event, value) => {
                  setFormValues({
                    ...formValues,
                    employeeRange: value,
                  })
                  setEmployeeRange(
                    businessEmployees[lang].filter((r) => r.value === value)
                  )
                }}
                items={businessEmployees[lang]}
              />
            </PlanContainer>
          </>
        )}
        <SubmitButton
          iconName="save"
          isLoading={isLoading}
          onClick={onSaveData}
          labelName={t("save_button")}
        />
      </InfoWrapper>
    </Container>
  )
}

export const Checks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  margin: 0px 16px;
`

const Container = styled.div`
  position: relative;
  height: calc(100% - 56px);
`

const ImagesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 190px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span {
    margin-top: 5px;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    text-align: center;
  }
`

const PlanContainer = styled.div`
  padding: 24px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 400px;
  text-align: center;
`

OrgInfo.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    userType: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  firebase: PropTypes.shape().isRequired,
  organization: PropTypes.shape().isRequired,
}

export default withFirebase(OrgInfo)
